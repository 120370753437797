import React from "react"
import CareerDetail from "../../../components/CareerDetail"
import Layout from "../../../components/Layout"
import positions from "../../../components/shared/Data/positions"
export default function SinglePosition(req, res) {
  const { id } = req.params
  const positionDetails = positions.filter(pos => pos.id == id)

  return (
    <Layout>
      <CareerDetail details={positionDetails[0]} />
    </Layout>
  )
}
